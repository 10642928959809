import React, { useState } from 'react'
import {Carousel} from "react-responsive-carousel";
import "./Carousel.css";
import tech8 from "../../assets/tech8.webp"
import tech5 from "../../assets/tech5.webp"
import tech6 from "../../assets/tech7.webp"
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useEffect } from 'react';

const CarouselComponent = () => {


  const [currentslide,setcurrentSlide] =useState(0);
  const [direction,setDirection]=useState("forward");
  const totalslides=3;

useEffect(()=>{
  const interval= setInterval(()=>{
   if(direction === "forward"){
    if(currentslide < totalslides-1){
      setcurrentSlide(currentslide+1);
    }
    else{
      setDirection("backward");
      setcurrentSlide(currentslide-1);
    }
   }else{
    if(currentslide > 0){
      setcurrentSlide(currentslide-1)
    }
    else{
      setDirection("forward");
      setcurrentSlide(currentslide+1);
    }
   }
  },3000);
return ()=>clearInterval(interval);

},[currentslide,direction]);

  return (
    <Carousel
  selectedItem={currentslide}
  showArrows={true}
  autoPlay={true}
  infiniteLoop={false}
  showThumbs={false}
  showStatus={true}
  interval={3000}
  transitionTime={1200}   
  stopOnHover={true}     
  showIndicators={true}
  >
    
      <div className= "carousel">
        <div className='carousel-slide slide1'>
        <img src={tech8} alt="Slide 1" loading='lazy' />
        <div className='carousel-text'>
         <h1><span className='uni2'>IOT</span>Automation solution company</h1>
         <p>Transforming technology into innovation involves applying technological advancements in new and creative ways to solve problems,improve processes,or create new products and services
         </p>
          </div>
         </div>
      </div>

      <div className= "Carousel ">
        <div className='carousel-slide slide2'>
        <img src={tech5} alt="Slide 1" loading='lazy' />
        <div className='carousel-text'>
         <h1 className='uni3'>Go Beyond To Next Gen</h1>
          </div>
         </div>
      </div>

      <div className= "Carousel ">
        <div className='carousel-slide slide3'>
        <img src={tech6} alt="Slide 1" loading='lazy' />
        <div className='carousel-text'>
         <h1 className='uni4'>Future <br/>Revolution..</h1>
          </div>
         </div>
      </div>

    </Carousel>
  )
}

export default CarouselComponent;