import React,{useEffect, useState} from 'react'
import "./Navbar.css"
import logo from "../../assets/logo.png.jpg";
import {Link} from "react-scroll";

const Navbar = () => {
 
  const [sticky,setSticky]=useState(false);

  useEffect(()=>{
      window.addEventListener('scroll',()=>{
        window.scrollY > 50 ? setSticky(true) : setSticky(false);
      })

  },[]);

  
  return (
    <nav className={`container ${sticky ? "dark-nav" :" "}`}>
        <img src={logo} alt=""  className='logo'/>
        <ul className='hover'>
            <li><Link to="carousel" smooth={true} offset={0} duration={700}>Home</Link></li>
            <li><Link to="service" smooth={true} offset={-260} duration={900}>Services</Link></li>
            <li><Link to="product" smooth={true} offset={-330} duration={500}>Products</Link></li>
            <li><Link to="about" smooth={true} offset={-270} duration={500}>About Us</Link></li>
            <li><Link to="testimonials" smooth={true} offset={-260} duration={500}>Testimonials</Link></li>
            <li className='unique'><Link to="contact" smooth={true} offset={-270} duration={500}>Contact Us</Link></li>
        </ul>
    </nav>
  )
}

export default Navbar;