import React from 'react'
import "./Aboutus.css"
import play_icon from "../../assets/play-button.png"
import about_img from "../../assets/about.webp"
const About= () => {
  return (
    <div className='about'>
        <div className='about-left'>
             <img src={about_img} alt=""  className='about-img'/>
             <img src={play_icon} alt=""  className='play-icon'/>
        </div>
         <div className='about-right'>
              <h2>About Company</h2>
              <p>For over 8 years (since 2016),has been at the forefront of the IoT automation industry, pioneering innovative solutions that transform how businesses operate. Our journey began with a simple yet powerful vision: to harness the power of the Internet of Things (IoT) to create smarter, more efficient, and interconnected environments.
                we are a team of passionate engineers, developers, and visionaries dedicated to pushing the boundaries of what is possible with IoT technology.
              </p>
         <p>
        <span className='unique1'>Our mission </span> is to empower businesses with cutting-edge IoT solutions that drive efficiency, productivity, and innovation. We strive to create systems that not only meet today’s demands but are also scalable and adaptable to future technological advancements.
         </p>
         </div>
    </div>
  )
}

export default About