import React from 'react'
import "./Tittle.css"

const Tittle = ({subTittle,tittle}) => {
  return (
    <div className='tittle'>
       <p>{tittle}</p>
       <h2>{subTittle}</h2>
    </div>
  )
}

export default Tittle