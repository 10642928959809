import React from 'react'
import "./Services.css"
import servicetech1 from "../../assets/microcontroller.webp"
import servicetech2 from "../../assets/fabrication.webp"
import servicetech3 from "../../assets/motorimg.jpg"
import servicetech4 from "../../assets/supply.webp"
import servicetech5 from "../../assets/applications.webp"
import servicetech6 from "../../assets/others.webp"
import icon1 from "../../assets/icon2.png"
import icon2 from "../../assets/welding.svg"
import icon3 from "../../assets/motor.png"
import icon4 from  "../../assets/monitor.svg"
import icon5 from "../../assets/power.svg"
import icon6 from "../../assets/hand-shake.png"

const Services = () => {
  return (
    <>
    <div className='services'>
      <div className='service'>
        <img src={servicetech1} alt="" />
        <div className='caption'>
          <div className='pair'>
          <img src={icon1} alt="" />
          <h1>MicroController based automation</h1>
          </div>
          <p> Microcontroller-based projects involve using microcontrollers to create systems that perform specific tasks, often in the realm of automation, control, and data processing
           some of Arduino, PIC, AVR, ARM Cortex, ESP8266, ESP32.
          </p>
        </div>
      </div>
      <div  className='service'>
         <img src={servicetech2} alt="" />
         <div className='caption'>
          <div className='pair'>
          <img src={icon2} alt="" />
          <h1>Design and fabrication</h1>
          </div>
          <p> Computer-Aided Design (CAD): Using CAD software to create detailed 3D models and technical drawings. CAD tools help in visualizing the product, making modifications, and ensuring accuracy
          Building prototypes to test the design physically. Prototyping can involve 3D printing, CNC machining, or other rapid prototyping techniques.
          </p>
        </div>
      </div>
      <div  className='service'>
         <img src={servicetech3} alt="" />
         <div className='caption'>
          <div className='pair'>
          <img src={icon3} alt="" />
          <h1>Servo Motors & Drives</h1>
          </div> 
          <p>Servo motors are known for their precision and are widely used in applications requiring exact control, such as robotics, CNC machinery, and automated manufacturing lines
          These motors operate at a constant speed up to full load and are used in applications where precise timing and speed are essential,such as clocks,record players,and timing devices in industrial applications​.
          </p>
        </div>
      </div>
    </div>
   <div className='services'>
   <div  className='service'>
         <img src={servicetech4} alt="" />
         <div className='caption'>
          <div className='pair'>
          <img src={icon5} alt="" />
          <h1>Dc Regulated & PowerSupply</h1>
          </div>
          <p>Adjustable DC Power Supply-Build a DC power supply with adjustable voltage output.Provides a stable and adjustable voltage source for testing and debugging electronic circuits.
            Transformer, bridge rectifier,capacitors,LM317 voltage regulator,potentiometer,heat sink,and display.
          </p>
        </div>
      </div>
      <div  className='service'>
         <img src={servicetech6} alt="" />
         <div className='caption'>
          <div className='pair'>
          <img src={icon6} alt="" />
          <h1>others</h1>
          </div>
          <p>We specialize in a wide range of industrial and IoT projects,leveraging cutting-edge technology to deliver innovative solutions. Our expertise spans various industries, enabling us to design and implement systems that optimize efficiency and productivity. From automated manufacturing lines to smart factory solutions, our industrial projects are tailored to meet the unique needs of our clients​.
          </p>
        </div>
      </div>
      <div  className='service'>
         <img src={servicetech5} alt="" />
         <div className='caption'>
          <div className='pair'>
          <img src={icon4} alt="" />
          <h1>Web-applications</h1>
          </div>
          <p>
We develop a web applications are designed to cater to a diverse range of industries and business needs. 
By leveraging the latest technologies, we ensure our solutions are efficient, scalable, and more user-friendly & userExperiance. Contact us to learn more about how our web applications can support your business goals.
          </p>
        </div>
      </div>
   </div>
  
  
    </>
  )
}

export default Services
