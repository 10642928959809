import React from 'react'
import "./Contact.css"
import phoneicon from "../../assets/phone-call.png"
import mailicon from "../../assets/mail.png"
import locationicon from "../../assets/location.png"
import msgicon from "../../assets/paper-plane.png"
import btn_arrow from "../../assets/button-arrow.png"

const Contact = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "d93af9cf-becc-44e8-814c-4f48e80a5dfa");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className='contact'>
        <div className="contact-col">
          <h3>Send us a message <img src={msgicon} alt="" /></h3>
          <p>Feel free to reach out through contact form or find out our contact information below.
          <p>your feedback, questions, and suggestions are important to us.</p>
          </p>
          <ul>
            <li><img src={mailicon} alt="" />infinityelx@gmail.com</li>
            <li><img src={phoneicon} alt="" />+91 81243-44786</li>
            <li><img src={locationicon} alt="" />354,E,Sanganoor Main Road, <br/>Ganapathy,Coimbatore,Tamilnadu 641-006.</li>
            <li><iframe title="infiniti automation"src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.044110173873!2d76.96990197480886!3d11.035317489129604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858680351b471%3A0x4af0ab9ee84f4576!2sInfiniti%20Automation!5e0!3m2!1sen!2sin!4v1721646504244!5m2!1sen!2sin"  width="400" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></li>
            <li><a href="https://maps.app.goo.gl/qb99J5wddA9oo6Mb7" target='_blank'  rel="noopener noreferrer">Click here to view location</a></li>
          </ul>
        </div>
        <div className="contact-col">
              <form onSubmit={onSubmit}>
                <label >Your name:</label>
                <input type="text" name="name" placeholder='Enter your name' required/>
                <label >Phone number:</label>
                <input type="tel" name="phone" placeholder='Enter your mobile number ' required />
               <label > Write your messages here:</label>
               <textarea name="message" rows="6" placeholder='Type your message' required></textarea>
               <button type='submit' className='btn'>Submit <img src={btn_arrow} alt="" /></button>
              </form> 
              <span>{result}</span>
       </div>
    </div>
  )
}

export default Contact