
import React from 'react'

import Navbar from './Components/Navbar/Navbar'
import Carousel from './Components/Carousel/CarouselComponent';
import Services from './Components/Services/Services';
import Tittle from './Components/Tittle/Tittle';
import Products from './Components/Products/Products';
import About from './Components/Aboutus/Aboutus';
import Testimonials from './Components/Testimonials/Testimonials';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';



const App = () => {
  return (
    <div>
       <Navbar/>
       <Carousel/>
       <div className='container'>
         <Tittle subTittle="We Offer a Comprehensive Range of Services" tittle="Our Services" />
         <Services/>
         <Tittle subTittle="We Deliver Wide Range of Products" tittle="Our Products" />
         <Products/>
         <Tittle subTittle="We Team of Dexterous IoT Automation Technocrat" tittle="About us"/>
         <About/>
         <Tittle subTittle="What Our Client Says" tittle="Testimonials"/>
         <Testimonials/>
         <Tittle subTittle="Get in Touch" tittle="Contact Us"/>
         <Contact/>
         <Footer/>
       </div>
      
    </div>
  )
}

export default App;

