import React, { useRef } from 'react'
import "./Testimonials.css"
import nextbtn from "../../assets/rightarrow.png"
import backbtn from "../../assets/leftarrow.png"
import user from "../../assets/user.png"
//import rating1 from "../../assets/rating.png"
const Testimonials = () => {

    const slider = useRef();
    let tx=0; 


 
    const slideForward=()=>{
      if(tx > -50){
        tx = tx-25;
      }
      slider.current.style.transform = `translateX(${tx}%)`;

    }

    const slideBackward=()=>{
        if(tx < 0){
            tx += 25;
          }
          slider.current.style.transform = `translateX(${tx}%)`;
    
    }


  return (

    <div className='testimonials'>
        <img src={nextbtn} alt=""  className='next-btn' onClick={slideForward}/>
        <img src={backbtn} alt="" className='back-btn'onClick={slideBackward} />
        <div className="slide">
            <ul ref={slider} >
                <li>
                    <div className="sliding">
                        <div className="user-info">
                            <img src={user} alt="" />
                            <div >
                                <h2>Shajagaan 97</h2>
                            
                            </div>
                        </div>
                        <p>A good place to develop a new products and projects ideas at reasonable price. I have developed a POWER ELECTRONICS project and a water controller project ,both are working good
                        </p>
                    </div>
                </li>
                <li>
                    <div className="sliding">
                        <div className="user-info">
                            <img src={user} alt="" />
                            <div>
                                <h2>Yuva Raja</h2>
                            </div>
                        </div>
                        <p>
                        They are developing  motor controllers and personalized customer specific products. We have purchased a personalized 3 phase and 1 phase motor controller; completely development to implement in special requirements for defense systems
                        </p>
                    </div>
                </li>
                <li>
                    <div className="sliding">
                        <div className="user-info">
                            <img src={user} alt="" />
                            <div>
                                <h2>prabhu Balasundharam</h2>
                            </div>
                        </div>
                        <p>
                          Infiniti is one of the best company for doing products and college projects.they have more confident doing job. One of the most developing company.i am wishing all best for each and every one who's contributed in this.
                        </p>
                    </div>
                </li>
                <li>
                    <div className="sliding">
                        <div className="user-info">
                            <img src={user} alt="" />
                            <div>
                                <h2>Dhivakar</h2>
                            </div>
                        </div>
                        <p>
                        I'm satisfied with their work.
                        They deliver the projects and products on time. Experienced people working on the products and projects.Go for it !
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Testimonials