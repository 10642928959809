import React from 'react'
import "./Footer.css"
import copyright_icon from "../../assets/copyright.png"

const Footer = () => {
  return (
    <div className='footer'>
        <p><img src={copyright_icon}alt="" />Copyrights 2016-2024 Infiniti. All Rights Reversed.</p>
        <ul>
            <li>Terms of Services</li>
            <li>Privacy Policy</li>
        </ul>
    </div>
  )
}

export default Footer