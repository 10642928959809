import React from 'react'

import "./Products.css"
import product1 from "../../assets/controller.webp"
import product2 from "../../assets/cad.webp"
import product3 from "../../assets/iot.webp"
const Products = () => {
  return (
     <>
    <div className='overall-bg'>
    <div className='product'>
        <div className='product-left'>
            <img src={product1} alt="" className='product-img'/>
        </div>
         <div className='product-right'>
            <h1>About Products</h1>
            <h2>Micro-Controller</h2>
            <p>Microcontrollers are compact integrated circuits designed to govern specific operations in embedded systems. 
                These tiny computers contain a processor core, memory,and programmable input/output peripherals, 
                enabling them to control various devices and processes.Commonly found in automotive, medical,and consumer electronics, 
                microcontrollers manage functions like engine control systems, medical devices, and home appliances. 
                They operate on simple programs stored in their onboard memory, making them efficient for real-time tasks.
                 Popular microcontroller families include Arduino, PIC, and ARM Cortex. With the rise of the Internet of Things (IoT), 
                 microcontrollers have become crucial in creating smart, connected devices. They offer a cost-effective solution for
                 implementing control and automation in a wide range of applications.</p>
        </div>
    </div>
    
    
     <div className='product'>
     <div className='product-right'>
         <h2>Fabrication Design</h2>
         <p>SolidWorks is a leading CAD (Computer-Aided Design) software widely used in engineering,design,and manufacturing. 
            Known for its powerful 3D modeling capabilities,SolidWorks allows engineers and designers to create detailed and accurate models of products and components. The software supports a range of functionalities including part and assembly modeling, drawing creation, and simulation. SolidWorks user-friendly interface and comprehensive toolset make it ideal for designing complex mechanical systems, evaluating design performance, and managing product data. It also integrates well with other engineering tools and workflows, enhancing productivity and collaboration. Additionally, SolidWorks offers specialized features for sheet metal, welding, and mold design, making it versatile across various industries.
             Its robust simulation tools allow for stress analysis, thermal analysis, and fluid dynamics.
        </p>
    </div>
     <div className='product-left'>
         <img src={product2} alt="" className='product-img'/>
     </div>
  </div>
  
  <div className='product'>
  <div className='product-left'>
         <img src={product3} alt="" className='product-img'/>
     </div>
     <div className='product-right'>
         <h2>Internet Of Things</h2>
         <h1>Enhancing productivity and efficiency in industrial environments.</h1>

 <p>Remote Monitoring Systems: Keep track of your industrial processes with real-time data analytics, ensuring optimal performance and predictive maintenance.</p>
  <p>Smart Sensors: Deploy sensors for temperature, humidity, pressure, and more to collect critical data and improve operational efficiency.</p>
 <p>Automation Controllers: Automate complex industrial processes with our robust and scalable automation controllers.</p>
    </div>
  </div>
  </div>
 </>
  )
}

export default Products